import React from 'react';

function TestClient(props) {
    return (
        <div className="testclient">
            <span className="client client1">
                <img src={ require("../../../assets/images/test/testimg1.png") } alt="testimg"/>
                <span className="tltp blue">Hey You're amazing</span>
            </span>
            <span className="client client2">
                <img src={ require("../../../assets/images/test/testimg2.png") } alt="testimg"/>
                <span className="tltp green">Hey You're amazing</span>
            </span>
            <span className="client client3">
                <img src={ require("../../../assets/images/test/testimg3.png") } alt="testimg"/>
                <span className="tltp purple">Somthing Here</span>
            </span>
            <span className="client client4">
                <img src={ require("../../../assets/images/test/testimg4.png") } alt="testimg"/>
                <span className="tltp chklt">Somthing Here</span>
            </span>
            <span className="client client5">
                <img src={ require("../../../assets/images/test/testimg5.png") } alt="testimg"/>
                <span className="tltp red">Somthing Here</span>
            </span>
            <span className="client client6">
                <img src={ require("../../../assets/images/test/testimg6.png") } alt="testimg"/>
                <span className="tltp blue">Somthing Here</span>
            </span>
            <span className="client client7">
                <img src={ require("../../../assets/images/test/testimg7.png") } alt="testimg"/>
                <span className="tltp green">Somthing Here</span>
            </span>
            <span className="client client8">
                <img src={ require("../../../assets/images/test/testimg8.png") } alt="testimg"/>
                <span className="tltp chklt">Somthing Here</span>
            </span>
            <span className="client client9">
                <img src={ require("../../../assets/images/test/testimg9.png") } alt="testimg"/>
                <span className="tltp red">Somthing Here</span>
            </span>
            <span className="client client10">
                <img src={ require("../../../assets/images/test/testimg10.png") } alt="testimg"/>
                <span className="tltp purple">Somthing Here</span>
            </span>
            <span className="client client11">
                <img src={ require("../../../assets/images/test/testimg11.png") } alt="testimg"/>
                <span className="tltp green">Somthing Here</span>
            </span>
        </div>
    )
}

export default TestClient;
